/* * {
  background-color: #111;
} */

.Home {
  text-align: center;
  background-color: #111;
  font-family: Roboto Mono;
  color: white;
  position: relative;
}

.Home__Cadastre {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  position: relative;
}

.Home__Cadastre__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.Home__Cadastre__logo img {
  width: 150px;
  height: 100%;
  justify-content: center;
}

.Cadastre {
  display: flex;
  width: auto;
  justify-content: center;
}

.Cadastre__container {
  width: auto;
}

.Cadastre__imgZone {
  width: 150px;
  height: 100%;
  cursor: pointer;
}

.Cadastre__imgZone:hover {
  transform: scale(2);
  transition: all 0.5s ease;
  border-radius: 75px;
}

.Cadastre__popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 5%;
  top: 10%;
  background-color: rgba(229, 62, 18, 0.9);
  color: white;
  padding: 20px 20px;
  border-radius: 20px;
  width: 30%;
  height: 100vh;
  overflow: auto;
  align-items: flex-start;
}

.Cadastre__popup::-webkit-scrollbar {
  display: none;
}

.Cadastre__popup img {
  width: 100%;
  border-radius: 20px;
}

.Cadastre__popup button {
  background-color: #111;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
}

.Cadastre__popup button:hover {
  background-color: #e53e12;
  color: white;
  transition: all 0.5s ease;
}

.Cadastre__popup__content {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  justify-content: flex-start;
}



.Home-header {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.Home-header__cover {
  top: 0;
  position: sticky;
  left: 0;
  /* width: 50%; */
  flex: 2;
  height: 100%;
  z-Index: "1";
}

.Home-header__cover img {
  width: auto;
  height: 100vh;
  background-position: cover;
}

.Home-header__cover__typewrite {
  align-items: center;
  margin:30% auto 20px auto;
  justify-content:center;
  height:auto;
  background-color:rgba(0,0,0,0.25);
  font-size:1.4rem;
  font-family: Roboto Mono;
  font-weight:200;
  padding:10px 20px;
}

.Home-header__cover__typewrite__msg {
  align-items: center;
  margin:0;
  justify-content:center;
  height:auto;
  /* background-color:rgba(0,0,0,0.25); */
  font-size:1.2rem;
  font-family: Roboto Mono;
  font-weight:200;
  padding:10px 40px;
  opacity: 0.7;
}

.Home-header__form {
  padding: 40px 80px;
  overflow-y: scroll;
  /* width: 50%; */
  flex: 1;
  height: "100%";
  scroll-behavior: "smooth";
}

.Home-header__form__sent {
  padding: 40px;
  overflow-y: scroll;
  flex: "1";
  height: "100%";
  scroll-behavior: "smooth";
  max-width: 400px;
}

/* Hide scrollbar for all elements with overflow-y: scroll */
::-webkit-scrollbar {
  width: 0.5em;
}

/* Hide scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 2em;
}

/* Hide scrollbar track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

.Home-header__form img {
  width: 50%;
  padding: 40px 0;
}

.Home-header__form__sent img {
  max-width: 250px;
  padding: 40px 0;
}

.Home-header__form h2 {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0 40px;
}

.Home-header__form__sent h2 {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0 20px;
}

.Home-header__form__info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Home-header__form__info ul {
  padding: 0 20px;
}

.Home-header__form__info ul li {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.Home-header__form__info ul li input {
  font-family: Roboto Mono;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  margin: 10px 0;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
}

.Home-header__form__info ul li input::placeholder {
  opacity: 1;
}

.Home-header__form__info__select {
  padding: 0;
  margin: 0;
}

.Home-header__form__info__select p {
  font-size: 1rem;
  text-align: center;
  padding-top: 10px;
}

.Home-header__form__info__select select {
  padding: 10px 20px;
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: white;
  margin: 10px 0;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
}

.Home-header__form__info__paybutton {
  display: flex;
  padding: 0 40px;
}

.Home-header__form__info__paybutton button {
  font-family: Roboto Mono;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #111;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.Home-header__form__info__paybutton button:hover {
  background-color: orangered;
  transition: all 0.5s ease;
  color: white;
}

option {
  color: #111;
  padding: 10px 0;
  font-family: Roboto Mono;
}

.Home-header__form__copyright {
  padding-top: 40px;
}

.Home-header__form__copyright p {
  font-size: 1rem;
  opacity: 0.8;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 999px) {
  .Home-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .Home-header__cover__typewrite {
    align-items: center;
    margin:10% auto 20px auto;
    justify-content:center;
    height:auto;
    background-color:rgba(0,0,0,0.25);
    font-size:1.2rem;
    font-family: Roboto Mono;
    font-weight:200;
    padding:10px 20px;
  }

  .Home-header__cover__typewrite__msg {
    align-items: center;
    margin:0;
    justify-content:center;
    height:auto;
    /* background-color:rgba(0,0,0,0.25); */
    font-size:1rem;
    font-family: Roboto Mono;
    font-weight:200;
    padding:10px 20px;
    opacity: 0.7;
  }

  .Home-header__form {
    padding: 40px 20px;
  }

  .Home-header__form h2 {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0 20px;
  }
}

@media (min-width: 1000px) {
  
}