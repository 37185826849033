.banner {
  height: 100vh;
  width: 100%;
}

.resetpassword {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(17, 17, 17, 0);
  height: 100vh;
  width: 100%;
}

.resetpassword__container {
  color: #fff;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgba(17, 17, 17, 0.9);
  max-height: 600px;
  max-width: 600px;
  border-radius: 10px;
}

.resetpassword__msg {
  font-size: 1.6rem;
  justify-content: center;
  align-items: center;
}

.resetpassword__msg > img {
  padding: 20px 0;
  width: 200px;
  cursor: pointer;
}

.resetpassword form {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.resetpassword form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  width: auto;
  border-radius: 5px;
  margin: 0 2px;
  font-size: 1rem;
}

.resetpassword form input::placeholder {
  color: #111;
  font-size: 1rem;
  align-items: center;
  margin: auto;
}

.resetpassword form > button {
  width: auto;
  padding: 10px 10px;
  margin: 0 5px;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--demoqrata-three);
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.resetpassword form > button:hover {
  background-color: var(--demoqrata-one);
}

.resetpassword form > p {
  font-size: 1.2rem;
  opacity: 0.8;
  margin-top: 10px;
}

.resetpassword__help > p {
  font-size: 1rem;
  padding: 20px 0;
}

.resetpassword__help__signup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetpassword__help__signup p {
  padding: 10px 0;
  font-size: 1.2rem;
  opacity: 0.8;
}

.resetpassword__help__signup span {
  font-size: 1.2rem;
  padding-left: 10px;
  opacity: 1;
}

/* Phones and Tablet*/

@media (max-width: 799px) {

  .resetpassword__msg {
    padding: 0;
    margin: 0;
  }

  .resetpassword__msg p {
    padding: 10px 0;
  }

  .resetpassword__msg img {
    padding: 10px 0;
    margin: 0;
  }

  .resetpassword__container form {
    padding: 20px 0;
    flex-direction: column;
  }

  .resetpassword__container form input {
    margin: 10px 0;
  }

  .resetpassword__container form button {
    margin: 10px 0;
  }

  .resetpassword__help__signup {
    flex-direction: column;
    padding: 10px 0;
  }
}

/* Desktop */
@media (min-width: 800px) {
}
