.TokenScreen {
    padding: 20px 40px;
    color: white;
}

.TokenScreen__return {
    display: flex;
    cursor: pointer;
    justify-content: left;
    align-items: center;
}

.TokenScreen__return:hover {
    opacity: 0.8;
    transition: all 0.5s ease;
}

.TokenScreen__mainmsg {
    font-size: 2rem;
    font-weight: 3rem;
}

.TokenScreen__level1 {
    display: flex;
}

.TokenScreen__level1__image img {
    max-width: 450px;
    border-radius: 20px;
}

.TokenScreen__level1__data {
    padding: 0 40px;
}

.TokenScreen__level1__data h2 {
    color: #ed651b;
    font-size: 1.8rem;
}

.TokenScreen__level1__data__quantity {
    padding: 20px 0;
}

.TokenScreen__level1__data__quantity__buttons {
    display: flex;
}

.TokenScreen__level1__data__quantity__buttons button {
    background-color: #ed651b;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1rem;
}

.TokenScreen__level1__data__quantity__buttons p {
    padding: 0 20px;
}

.TokenScreen__level3 {
    padding: 20px 0;
}

.TokenScreen__level3 div {
    display: flex;
}

.TokenScreen__level3 div button {
    background-color: darkred;
    padding: 20px;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    margin-right: 20px;
}

.TokenScreen__level3 div button:hover {
    background-color: #111;
    transition: all 0.5s ease;
}

.TokenScreen__send {
    padding: 20px 0;
}

.TokenScreen__send button {
    background-color: darkorange; cursor:pointer;
    padding:10px 20px;
    color: #111;
}

.TokenScreen__send button:hover {
    background-color: #ed651b;
    transition: all 0.5s ease;
}