* {
    font-family: Poppins;
}

.documentation__nav {
    display: flex;
    padding: 20px 40px;
    width: auto;
    justify-content: space-between;
    align-items: center;
    margin: auto 0;
}

.documentation__nav img {
    width: 150px;
    height: 100%;
}

.documentation__nav p > span {
    opacity: 0.6;
    padding-left: 20px;
    font-size: 0.8rem;
}

.documentation-container {
    display: flex;
    padding: 20px;
    border: 1px solid lightgray;
    height: 100vh; /* Adjust the height as needed */
  }
  
  .topics-list-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .topics-list {
    list-style: none;
    padding: 0;
  }
  
  .topics-list li {
    cursor: pointer;
    margin: 5px 0;
    font-size: 1rem;
  }

  .topics-list li:hover {
    color: red;
    transition: all 0.5s ease;
    font-weight: 600;
  }
  
  .content-container {
    flex: 2;
    overflow-y: auto;
    padding: 10px;
  }

  .content__detail h2 {
    opacity: 0.6;
  }

  .content__detail p {
    white-space: pre-line;
    font-size: 1rem;
    line-height: 1.8rem;
  }
  
  .content {
    padding: 20px;
  }

  .content__notopic {
    font-size: 1.4rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    width: auto;
    border-radius: 20px;
    border: none;
    justify-content: center;
    text-align: center;
  }
  
  @media (max-width: 799px) {
    .documentation__nav {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: auto;
        justify-content: center;
        align-items: center;
        margin: auto 0;
    }
  }